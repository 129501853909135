<template>
	<div>
		<div>
			<b-card class="p-1" no-body>
				<b-row>
					<b-col class="d-flex align-items-center" cols="10">
						<b-nav pills class="m-0" style="gap: 1rem">
							<b-nav-item
								:to="{ name: 'brain-administrative-petty-cash' }"
								tag="li"
								active-class="nav-link active active-item-nav"
								link-classes="active-item-nav"
							>
								Caja chica
							</b-nav-item>

							<b-nav-item
								:to="{ name: 'brain-administrative-categories' }"
								tag="li"
								active-class="nav-link active active-item-nav"
								link-classes="active-item-nav"
							>
								Categorias
							</b-nav-item>

							<b-nav-item
								:to="{ name: 'brain-administrative-sub-categories' }"
								tag="li"
								active-class="nav-link active active-item-nav"
								link-classes="active-item-nav"
							>
								Sub Categorias
							</b-nav-item>

							<b-nav-item
								:to="{ name: 'brain-administrative-weight-units' }"
								tag="li"
								active-class="nav-link active active-item-nav"
								link-classes="active-item-nav"
							>
								Presentaciones
							</b-nav-item>

							<b-nav-item
								:to="{ name: 'brain-administrative-suppliers' }"
								tag="li"
								active-class="nav-link active active-item-nav"
								link-classes="active-item-nav"
							>
								Proveedores
							</b-nav-item>

							<b-nav-item
								:to="{ name: 'brain-administrative-brands' }"
								tag="li"
								active-class="nav-link active active-item-nav"
								link-classes="active-item-nav"
							>
								Marcas
							</b-nav-item>
						</b-nav>
					</b-col>
					<b-col class="d-flex justify-content-end" style="gap: 1rem">
						<b-button
							variant="success"
							v-if="getRouteName == 'brain-administrative-categories'"
							@click="createCategories"
						>
							Crear categoría
						</b-button>

						<b-button
							variant="success"
							v-if="getRouteName == 'brain-administrative-weight-units'"
							@click="createWeight"
						>
							Crear presentacion
						</b-button>
						<b-button
							variant="success"
							v-if="getRouteName == 'brain-administrative-suppliers'"
							@click="createProvider"
						>
							Crear proveedores
						</b-button>
						<b-button
							variant="success"
							v-if="getRouteName == 'brain-administrative-brands'"
							@click="createBrands"
						>
							Crear Marcas
						</b-button>
						<b-button
							variant="success"
							v-if="getRouteName == 'brain-administrative-sub-categories'"
							@click="createSubCategory"
						>
							Crear sub-categoria
						</b-button>
					</b-col>
				</b-row>
			</b-card>
			<b-card class="p-1" no-body>
				<router-view
					ref="view-component"
					:action="childAction"
					@actionDone="childAction = ''"
					@blocked="actionBlocked = true"
				/>
			</b-card>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		childAction: "",
		actionBlocked: false,
	}),

	computed: {
		getRouteName() {
			return this.$route.name
		},
	},
	created() {},

	watch: {
		"$route.name"() {
			this.actionBlocked = false
		},
	},
	methods: {
		createCategories() {
			this.$refs["view-component"].createCategories()
		},
		createWeight() {
			this.$refs["view-component"].createWeight()
		},
		createProvider() {
			this.$refs["view-component"].createProvider()
		},
		createBrands() {
			this.$refs["view-component"].createBrands()
		},
		createSubCategory() {
			this.$refs["view-component"].createSubCategory()
		},
	},
}
</script>
